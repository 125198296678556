import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/faq",
    redirect: "/faq/list",
    name: "FaqManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "FAQ",
    iconSvg: "log",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/faq/list",
        name: "FaqsList",
        component: () => import("@/views/faqs/FaqsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "FAQ",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['FAQ_ADMIN']
        }
      },
      {
        path: "/faq/tags/list",
        name: "FaqTagsList",
        component: () => import("@/views/faqTags/FaqTagsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "FAQ分类",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['FAQ_ADMIN']
        }
      },


    ]
  }
];

export default RouteModules;
