<template>
  <el-form
    :model="form"
    status-icon
    :rules="rules"
    ref="form"
    label-width="100px"
    :disabled="submitting"
    class="password-form"
  >
    <el-form-item label="新密码" prop="newPassword">
      <el-input
        type="password"
        v-model="form.newPassword"
        clearable
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item label="旧密码" prop="oldPassword">
      <el-input
        type="password"
        v-model="form.oldPassword"
        clearable
        show-password
      ></el-input>
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "提交中..." : "提交修改" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PutCurrentUserPassword } from "@/views/users/api";
import { ShowApiError } from "@/request/error";
export default {
  data() {
    return {
      submitting: false,
      form: {
        newPassword: "",
        oldPassword: "",
      },
      rules: {
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutCurrentUserPassword(this.form)
            .then(() => {
              this.submitting = false;
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("请求异常", err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style></style>
