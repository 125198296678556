/* eslint-disable */
import { GetLengthWidthList } from "@/views/productlLengthwidth/api";
import { GetProductThicknessesList } from "@/views/productThicknesses/api";
import { GetProductSpecificationsList } from "@/views/productSpecifications/api";
import { GetProductEffectsList } from "@/views/productEffects/api";
import { GetProductUsagesList } from "@/views/productUsages/api";
import { GetProductColorsList } from "@/views/productColors/api";
import { GetSeriesList } from "@/views/productSeries/api";
import { GetProductsList } from "@/views/products/api";
const state = {
  length_widths: [],
  thicknesses: [],
  specifications: [],
  effects: [],
  usages: [],
  colors: [],
  series: [],
  series_loading: false,
  products: [],
  product_loading: false,
}

const mutations = {
  upDateLengthWidths(state, payload) {
    if (payload && payload instanceof Array) {
      state.length_widths = [...payload]
    }
  },
  upDateThicknesses(state, payload) {
    if (payload && payload instanceof Array) {
      state.thicknesses = [...payload]
    }
  },
  upDateSpecifications(state, payload) {
    if (payload && payload instanceof Array) {
      state.specifications = [...payload]
    }
  },
  upDateEffects(state, payload) {
    if (payload && payload instanceof Array) {
      state.effects = [...payload]
    }
  },
  upDateUsages(state, payload) {
    if (payload && payload instanceof Array) {
      state.usages = [...payload]
    }
  },
  upDateColors(state, payload) {
    if (payload && payload instanceof Array) {
      state.colors = [...payload]
    }
  },
  upDateSeries(state, payload) {
    if (payload && payload instanceof Array) {
      state.series = [...payload]
    }
  },
  toggleSeriesLoading(state, payload) {
    state.series_loading = payload
  },
  upDateProducts(state, payload) {
    if (payload && payload instanceof Array) {
      state.products = [...payload]
    }
  },
  toggleProductLoading(state, payload) {
    state.product_loading = payload
  }


}

const actions = {
  async refreshLengthWidthOptions({ commit }, query = {}) {
    let res = await GetLengthWidthList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateLengthWidths', content)
  },
  async refreshThicknessesOptions({ commit }, query = {}) {
    let res = await GetProductThicknessesList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateThicknesses', content)
  },
  async refreshSpecificationsOptions({ commit }, query = {}) {
    let res = await GetProductSpecificationsList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateSpecifications', content)
  },
  async refreshEffectsOptions({ commit }, query = {}) {
    let res = await GetProductEffectsList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateEffects', content)
  },
  async refreshUsagesOptions({ commit }, query = {}) {
    let res = await GetProductUsagesList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateUsages', content)
  },
  async refreshColorsOptions({ commit }, query = {}) {
    let res = await GetProductColorsList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateColors', content)
  },


  async refreshSeriesOptions({ commit }, query = {}) {
    try {
      commit('toggleSeriesLoading', true)
      let res = await GetSeriesList({
        page: 0, size: 99, lang: 'cn',
        disabled: false,
        ...query
      })
      let { content } = res.data;
      commit('upDateSeries', content)
      commit('toggleSeriesLoading', false)
    } catch (error) {
      commit('toggleSeriesLoading', false)
    }
  },

  async refreshProductOptions({ commit }, query = {}) {
    try {
      commit('toggleProductLoading', true)
      let res = await GetProductsList({
        page: 0, size: 30, lang: 'cn',
        disabled: false,
        ...query
      })
      let { content } = res.data;
      commit('upDateProducts', content)
      commit('toggleProductLoading', false)
    } catch (error) {
      commit('toggleProductLoading', false)
    }
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
