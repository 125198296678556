import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/article",
    redirect: "/article/list",
    name: "ArticlesManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "文章管理",
    iconSvg: "log",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/article/list",
        name: "ArticlesList",
        component: () => import("@/views/articles/ArticlesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "文章管理",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['ARTICLE_ADMIN']
        }
      },
      {
        path: "/article/tag/list",
        name: "ArticleTagList",
        component: () => import("@/views/articleTags/ArticleTagList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "文章标签",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['ARTICLE_ADMIN']
        }
      },


    ]
  }
];

export default RouteModules;
