import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "ProductManager";
const RouteModules = [
  {
    path: "/products",
    redirect: "/prosuct/series/list",
    name: "Products",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "产品管理",
    iconSvg: "departmentManager",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/product/list",
        name: "ProductsList",
        component: () => import("@/views/products/ProductsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品管理",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/form/:id?",
        name: "ProductFormPage",
        component: () => import("@/views/products/ProductFormPage.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "产品编辑",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/specifications/list",
        name: "ProductSpecificationsList",
        component: () => import("@/views/productSpecifications/ProductSpecificationsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品规格",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/series/list",
        name: "ProductSeriesList",
        component: () => import("@/views/productSeries/ProductSeriesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品系列",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/colors/list",
        name: "ProductColorsList",
        component: () => import("@/views/productColors/ProductColorsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品色系",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/thicknesses/list",
        name: "ProductThicknessesList",
        component: () => import("@/views/productThicknesses/ProductThicknessesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品厚度",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/length_width/list",
        name: "ProductlLengthwidthList",
        component: () => import("@/views/productlLengthwidth/ProductlLengthwidthList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品长宽",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/effects/list",
        name: "ProductEffectsList",
        component: () => import("@/views/productEffects/ProductEffectsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品效果",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/usages/list",
        name: "ProductUsagesList",
        component: () => import("@/views/productUsages/ProductUsagesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品用途",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/models/list",
        name: "ProductModelsList",
        component: () => import("@/views/productModels/ProductModelsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品型号",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/product/advantages/list",
        name: "ProductAdvantagesList",
        component: () => import("@/views/productAdvantages/ProductAdvantagesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "产品优势",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
    ]
  }
];

export default RouteModules;
