import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "ProductManager";
const RouteModules = [
  {
    path: "/engineering/case",
    redirect: "/engineering/case/list",
    name: "Products",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "案例管理",
    iconSvg: "departmentManager",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {

        path: "/engineering/case/list",
        name: "EngineeringCaseList",
        component: () => import("@/views/engineeringCases/EngineeringCaseList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "案例管理",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['ENGINEERING_CASE_ADMIN']
        }
      },
      {
        path: "/engineering/case/areas/list",
        name: "AreasList",
        component: () => import("@/views/engineeringCases/areas/AreasList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "区域",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/engineering/case/designers/list",
        name: "DesignersList",
        component: () => import("@/views/engineeringCases/designers/DesignersList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "设计方",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/engineering/case/developers/list",
        name: "DevelopersList",
        component: () => import("@/views/engineeringCases/developers/DevelopersList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "开发商",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
      {
        path: "/engineering/case/types/list",
        name: "TypesList",
        component: () => import("@/views/engineeringCases/types/TypesList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "类型",
          keepAlive: true,
          iconSvg: "systemSetting",
          iconFont: "",
          roles: ['PRODUCT_ADMIN']
        }
      },
    ]
  }
]
export default RouteModules;