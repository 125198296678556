/* eslint-disable */

import { getToken } from "@/utils/auth";
const state = {
	site_title: "此处是网站的标题",
	menus: [],
	isSideBarCollapse: false,
	codeToExport: false,
	version: '',
	website_path: [
		{
			path: '/',
			name: '首页'
		},
		{
			path: '/series',
			name: '系列'
		},
		{
			path: '/cases',
			name: '案例'
		},
		{
			path: '/stories',
			name: '资讯'
		},
		{
			path: '/aboutus',
			name: '关于我们'
		},

	],
	locale_columns: {
		cn: "",
		en: "",
		// es: "",
		// fr: "",
		// de: "",
		// pt: "",
		// ru: "",
		// ar: "",
		// ja: "",
		// ko: ""
	},
	locale_names: {
		cn: "中文",
		en: "英文",
		// es: "西文",
		// fr: "法文",
		// de: "德文",
		// pt: "葡萄牙文",
		// ru: "俄文",
		// ar: "阿拉伯文",
		// ja: "日文",
		// ko: "韩文"
	},
	predefine_colors: [
		'#ff4500',
		'#ff8c00',
		'#ffd700',
		'#90ee90',
		'#00ced1',
		'#1e90ff',
		'#c71585',
		'rgba(255, 69, 0, 0.68)',
		'rgb(255, 120, 0)',
		'hsv(51, 100, 98)',
		'hsva(120, 40, 94, 0.5)',
		'hsl(181, 100%, 37%)',
		'hsla(209, 100%, 56%, 0.73)',
		'#c7158577'
	],
	allRequests: []
}

const mutations = {

	SetSiteTitle(state, payload) {
		if (typeof payload === 'string') {
			state.site_title = payload
		}
	},
	SetMenus(state, payload) {
		if (payload.length > 0) {
			state.menus = payload
		}
	},
	setVersion(state, payload) {
		state.version = payload
	},
	SetCodeToExport(state, payload) {
		state.codeToExport = payload
	},
	ToggleSideBarCollapse(state) {
		state.isSideBarCollapse = !state.isSideBarCollapse
	},
	CloseSideBarCollapse(state) {
		state.isSideBarCollapse = true
	},
	OpenSideBarCollapse(state) {
		state.isSideBarCollapse = false
	},
	addRequest(state, payload) {
		if (payload) {
			payload.url = payload.url.split('?')[0]
		}
		state.allRequests.push(payload)
	},
	removeRequest(state, payload) {
		state.allRequests = state.allRequests.filter(item => {
			let payloadUrl = payload.url.split('?')[0];
			if (item.method == payload.method && payloadUrl == item.url) {
				return false
			}
			return true
		})
	},
	abortRequest(state, payload) {
		let target = state.allRequests.find(item => {
			let payloadUrl = payload.url.split('?')[0];
			return item.method == payload.method && payloadUrl == item.url;
		})
		if (target) {
			target.source.cancel();
			target.controller.abort()

			state.allRequests = state.allRequests.filter(item => {
				let payloadUrl = payload.url.split('?')[0];
				if (item.method == payload.method && payloadUrl == item.url) {
					return false
				}
				return true
			})
		}
	},
}

const actions = {


}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
