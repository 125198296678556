/* eslint-disable */
import { GetPageTypesList } from "@/views/pagesManager/pageTypes/api";
import { GetPageSectionsTypesList } from "@/views/pagesManager/pageSectionsTypes/api";
const state = {
  types: [],
  pageSectionTypes: [],
}

const mutations = {
  upDateTypes(state, payload) {
    if (payload && payload instanceof Array) {
      state.types = [...payload]
    }
  },
  upDatePageSectionTypes(state, payload) {
    if (payload && payload instanceof Array) {
      state.pageSectionTypes = [...payload]
    }
  },
}

const actions = {
  async refreshTypes({ commit }, query = {}) {
    let res = await GetPageTypesList({
      page: 0, size: 999,
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateTypes', content)
  },
  async refreshPageSectionTypes({ commit }, query = {}) {
    let res = await GetPageSectionsTypesList({
      page: 0, size: 999,
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDatePageSectionTypes', content)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
