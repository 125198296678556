"use strict";
/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import API_CONFIG from "./api.config";
import { logout, getToken, relogin } from "@/utils/auth";
import { commonError } from "./error";
// import store from "../store";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let apiurl = process.env.NODE_ENV === "production" ? API_CONFIG.BASE_V1_API : API_CONFIG.BASE_V1_TEST_API;

let config = {
    baseURL: apiurl,
    timeout: 60 * 5000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const apiv1 = axios.create(config);

apiv1.interceptors.request.use(
    config => {

        if (getToken()) {
            config.headers.Authorization = getToken();
            // config.headers['referrerPolicy'] = "no-referrer-when-downgrade";
        }
        // let fullUrl = config.baseURL + config.url
        // store.commit('app/abortRequest', {
        //     url: fullUrl,
        //     method: config.method,
        // })

        // const controller = new AbortController();
        // config.signal = controller.signal;
        // const CancelToken = axios.CancelToken;
        // const source = CancelToken.source();
        // config.cancelToken = source.token;
        // store.commit('app/addRequest', {
        //     url: fullUrl,
        //     method: config.method,
        //     controller: controller,
        //     source: source
        // })

        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
apiv1.interceptors.response.use(
    response => {
        // store.commit('app/removeRequest', {
        //     url: response.config.url,
        //     method: response.config.method,
        // })
        // Do something with response data
        if (response.data.status) {
            if (response.data.status === 401) {
                commonError("401错误", response.data)
                logout()
            }
            if (response.data.status === 404) {
                commonError("404错误", response.data)
            }
            if (response.data.status === 500) {
                commonError("服务器错误", response.data)
            }
            return Promise.reject(response.data);
        } else {
            return response;
        }
    },
    error => {
        if (error.response) {
            // store.commit('app/removeRequest', {
            //     url: error.response.config.url,
            //     methods: error.response.config.method,
            // })
            if (error.response.data.status === 401) {
                commonError("401错误", error.response.data)
                logout()
                // relogin()
            }
            if (error.response.data.status === 404) {
                commonError("404错误", error.response.data)
            }
            if (error.response.data.status >= 500) {
                commonError("服务器错误", error.response.data)
            }
            return Promise.reject(error.response.data);
        }

        return Promise.reject(error);
    }
);
export {
    apiv1
}

Plugin.install = function (Vue, options) {
    Vue.apiv1 = apiv1;
    window.apiv1 = apiv1;
    Object.defineProperties(Vue.prototype, {
        apiv1: {
            get() {
                return apiv1;
            }
        },
        $apiv1: {
            get() {
                return apiv1;
            }
        }
    });
};

Vue.use(Plugin);

export default Plugin;
