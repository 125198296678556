import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "ProductManager";
const RouteModules = [

    {
        path: "/pages/manager",
        name: "PageManagerList",
        redirect: "/pages/manager/list",
        component: BasicLayout,
        showChildren: true,
        groupTitle: "页面管理",
        iconSvg: "departmentManager",
        iconFont: "",
        onlyExtentdLayout: true,
        children: [
            // {
            //     path: "/pages/manager/list",
            //     name: "PageManagerList",
            //     component: () => import("@/views/pagesManager/PageManagerList.vue"),
            //     meta: {
            //         module: moduleName,
            //         isMenu: true,
            //         showChildren: false,
            //         title: "页面管理",
            //         keepAlive: true,
            //         iconSvg: "inventory",
            //         iconFont: "",
            //         roles: ['PAGE_ADMIN']
            //     }
            // },
            // {
            //     path: "/pages/manager/edit/:id?",
            //     name: "PageManagerEdit",
            //     component: () => import("@/views/pagesManager/PageManagerList.vue"),
            //     meta: {
            //         module: moduleName,
            //         isMenu: false,
            //         showChildren: false,
            //         title: "页面编辑",
            //         keepAlive: true,
            //         iconSvg: "inventory",
            //         iconFont: "",
            //         roles: []
            //     }
            // },
            {
                path: "/pages/sections/types",
                name: "PageSectionsTypesList",
                component: () => import("@/views/pagesManager/pageSectionsTypes/PageSectionsTypesList.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "页面区块类型",
                    keepAlive: true,
                    iconSvg: "departmentManager",
                    iconFont: "",
                    roles: ['PAGE_ADMIN']
                }
            },
            {
                path: "/pages/types",
                name: "PageTypesList",
                component: () => import("@/views/pagesManager/pageTypes/PageTypesList.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "页面类型",
                    keepAlive: true,
                    iconSvg: "departmentManager",
                    iconFont: "",
                    roles: ['PAGE_ADMIN']
                }
            },
        ]
    },



];

export default RouteModules;