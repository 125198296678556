const getters = {
  allRequests: state => state.app.allRequests,
  findRequest: (state) => (payload) => {
    return state.app.allRequests.find(item => {
      let payloadUrl = payload.url.split('?')[0];
      return item.method == payload.method && payloadUrl == item.url;
    })
  },
  roles: state => state.auth.roles,
  userInfo: state => state.auth.userInfo,
  hasUserInfo: state => state.auth.hasUserInfo,
  site_title: state => state.app.site_title,
  isSideBarCollapse: state => state.app.isSideBarCollapse,
  tagViews: state => state.tagsView.tagViews,
  showActiveDialog: state => state.auth.showActiveDialog,
  localeColumns: state => state.app.locale_columns,
  localeNames: state => state.app.locale_names,
  predefineColors: state => state.app.predefine_colors,
  lengthWidthsOptions: state => state.product.length_widths,
  thicknessesOptions: state => state.product.thicknesses,
  specificationOptions: state => state.product.specifications,
  effectOptions: state => state.product.effects,
  usagesOptions: state => state.product.usages,
  colorsOptions: state => state.product.colors,
  seriesOptions: state => state.product.series,
  seriesLoading: state => state.product.series_loading,
  productsOptions: state => state.product.products,
  productLoading: state => state.product.product_loading,

  areasOptions: state => state.cases.areas,
  designersOptions: state => state.cases.designers,
  developersOptions: state => state.cases.developers,
  typesOptions: state => state.cases.types,

  pageTypeOptions: state => state.page.types,
  pageSectionTypeOptions: state => state.page.pageSectionTypes,
};
export default getters;
