import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/partners",
    redirect: "/partners/list",
    name: "PartnersManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "合作伙伴",
    iconSvg: "log",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/partners/list",
        name: "PartnersList",
        component: () => import("@/views/partners/PartnersList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "合作伙伴",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['PAGE_ADMIN']
        }
      },
    ]
  }
];

export default RouteModules;
