import { apiv1 } from "@/request/apiv1";
import qs from "qs";

let baseUrl = '/products/usages'

export function GetProductUsagesList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`${baseUrl}?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function GetProductUsagesById(id = null) {
    return new Promise((resolve, reject) => {
        apiv1.get(`${baseUrl}/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PostProductUsages(params = []) {
    return new Promise((resolve, reject) => {
        apiv1.post(`${baseUrl}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PutProductUsages(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`${baseUrl}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function ToogleDisableByIds(ids = [], bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`${baseUrl}/disable?ids=${ids}&isTrue=${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}
/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
export function GetDisableCheck(ids = []) {
    return new Promise((resolve, reject) => {
        apiv1.get(`${baseUrl}/can/disable?ids=${ids}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

/**
 * 获取修改许可
 * @param {String,Number} id 
 */
export function GetUpdateCheck(ids = []) {
    return new Promise((resolve, reject) => {
        apiv1.get(`${baseUrl}/can/update?ids=${ids}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}
