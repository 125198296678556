import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "HomeManager";
const RouteModules = [
  {
    path: "/carousel",
    redirect: "/carousel/manager",
    name: "Companies",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "首页管理",
    iconSvg: "statisticAnalysis",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/carousel/manager",
        name: "CarouselManager",
        component: () => import("@/views/carouselManager/CarouselManager.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "轮播图管理",
          keepAlive: true,
          iconSvg: "statisticAnalysis",
          iconFont: "",
          roles: []
        }
      }
    ]
  }
];

export default RouteModules;
