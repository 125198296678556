/* eslint-disable */
import { GetEngineeringCasesAreasList } from "@/views/engineeringCases/areas/api";
import { GetEngineeringCasesDesignersList } from "@/views/engineeringCases/designers/api";
import { GetEngineeringCasesDevelopersList } from "@/views/engineeringCases/developers/api";
import { GetEngineeringCasesTypesList } from "@/views/engineeringCases/types/api";
const state = {
  areas: [],
  designers: [],
  developers: [],
  types: [],
}

const mutations = {
  upDateAreas(state, payload) {
    if (payload && payload instanceof Array) {
      state.areas = [...payload]
    }
  },
  upDateDesigners(state, payload) {
    if (payload && payload instanceof Array) {
      state.designers = [...payload]
    }
  },
  upDateDevelopers(state, payload) {
    if (payload && payload instanceof Array) {
      state.developers = [...payload]
    }
  },
  upDateTypes(state, payload) {
    if (payload && payload instanceof Array) {
      state.types = [...payload]
    }
  },
}

const actions = {
  async refreshAreasOptions({ commit }, query = {}) {
    let res = await GetEngineeringCasesAreasList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateAreas', content)
  },
  async refreshDesignersOptions({ commit }, query = {}) {
    let res = await GetEngineeringCasesDesignersList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateDesigners', content)
  },
  async refreshDevelopersOptions({ commit }, query = {}) {
    let res = await GetEngineeringCasesDevelopersList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateDevelopers', content)
  },
  async refreshTypesOptions({ commit }, query = {}) {
    let res = await GetEngineeringCasesTypesList({
      page: 0, size: 999, lang: 'cn',
      disabled: false,
      ...query
    })
    let { content } = res.data;
    commit('upDateTypes', content)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
