import { apiv1 } from "@/request/apiv1";
import qs from "qs";

let baseUrl = '/products'

export function GetProductsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`${baseUrl}?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function GetProductsById(id = null) {
    return new Promise((resolve, reject) => {
        apiv1.get(`${baseUrl}/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PostProducts(params = []) {
    return new Promise((resolve, reject) => {
        apiv1.post(`${baseUrl}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function PutProducts(params = []) {
    return new Promise((resolve, reject) => {
        apiv1.put(`${baseUrl}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

export function ToogleDisableByIds(ids = [], bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`${baseUrl}/disable?ids=${ids}&isTrue=${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


export function UpdateProductSeries(data = [], params = {},) {
    return new Promise((resolve, reject) => {
        apiv1.put(`${baseUrl}/update/series?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`, data)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

