import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/solutions",
    redirect: "/solutions/list",
    name: "SolutionsManager",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "解决方案",
    iconSvg: "log",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/solutions/list",
        name: "SolutionsList",
        component: () => import("@/views/solutions/SolutionsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "解决方案",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['SOLUTION_ADMIN']
        }
      },
      {
        path: "/solutions/tag/list",
        name: "SolutionsTagList",
        component: () => import("@/views/solutionTags/SolutionsTagList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "解决方案标签",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['SOLUTION_ADMIN']
        }
      },


    ]
  }
];

export default RouteModules;
